<template>
  <div class="text-center debug">
    <!-- BOC:[error] -->
    <ApiErrorDialog v-if="api.isError" :api="api" />
    <!-- EOC -->
    <v-menu bottom right :offset-y="true" :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="black" dark v-bind="attrs" v-on="on" small>
          <v-icon color="red"> mdi-record </v-icon> Debug
        </v-btn>
      </template>

      <v-list dark dense nav>
        <v-list-group color="red">
          <template v-slot:activator>
            <v-list-item-title>🐾Footprint</v-list-item-title>
          </template>

          <v-list-item-content>
            <v-btn small @click="resetFootprint">Reset</v-btn>
          </v-list-item-content>
        </v-list-group>
        <v-list-group color="red">
          <template v-slot:activator>
            <v-list-item-title>⚡Energy</v-list-item-title>
          </template>

          <v-list-item-content>
            <v-btn small @click="resetEnergy">Reset</v-btn>
          </v-list-item-content>
        </v-list-group>
        <v-list-group color="red">
          <template v-slot:activator>
            <v-list-item-title>🎟️Ticket</v-list-item-title>
          </template>

          <v-list-item-content>
            <v-text-field v-model="eventCode" outlined dense hide-details label="Event code"></v-text-field>
            <v-btn small @click="resetTicket">Reset</v-btn>
          </v-list-item-content>
        </v-list-group>
        <v-list-group color="red">
          <template v-slot:activator>
            <v-list-item-title>🪙Coin</v-list-item-title>
          </template>

          <v-list-item-content>
            <v-btn small @click="addCoin(20, 'goldcoin')">Add-20</v-btn>
            <v-btn small @click="addCoin(100, 'goldcoin')">Add-100</v-btn>
            <v-btn small @click="resetCoin()">Reset</v-btn>
          </v-list-item-content>
        </v-list-group>
        <v-list-group color="red">
          <template v-slot:activator>
            <v-list-item-title>🖌️Golden Brush</v-list-item-title>
          </template>

          <v-list-item-content>
            <v-btn small @click="addCoin(20, 'goldenbrush')">Add-20</v-btn>
            <v-btn small @click="addCoin(100, 'goldenbrush')">Add-100</v-btn>
            <v-btn small @click="resetGoldenBrush()">Reset</v-btn>
          </v-list-item-content>
        </v-list-group>
        <v-list-group color="red">
          <template v-slot:activator>
            <v-list-item-title>📋Tutorial</v-list-item-title>
          </template>

          <v-list-item-content>
            current tutorial -
            {{ tutorial.Ongoing ? tutorial.Ongoing : "Nothing" }}
            <v-btn
              small
              @click="resetTut(tutorial.Ongoing)"
              v-if="tutorial.Ongoing"
              >Reset Current</v-btn
            >
            <v-btn small @click="resetTut('all')">Reset All</v-btn>
          </v-list-item-content>
        </v-list-group>
        <v-list-group color="red">
          <template v-slot:activator>
            <v-list-item-title>✨Chest</v-list-item-title>
          </template>

          <v-list-item-content>
            <v-btn small @click="addTreasure(1, 'CHEST0010')"
              >Wood Chest +1</v-btn
            >
            <v-btn small @click="addTreasure(1, 'CHEST0020')"
              >Gold Chest +1</v-btn
            >
            <v-btn small @click="addTreasure(1, 'CHEST0030')"
              >Diamond Chest +1</v-btn
            >
          </v-list-item-content>
        </v-list-group>
        <v-list-group color="red">
          <template v-slot:activator>
            <v-list-item-title>🔈News</v-list-item-title>
          </template>

          <v-list-item-content>
            <v-btn small @click="resetNews()">Reset</v-btn>
          </v-list-item-content>
        </v-list-group>
      </v-list>
    </v-menu>

    <v-dialog v-model="api.isLoading" hide-overlay persistent width="300">
      <v-card color="black" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="red"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    tutorial: (state) => state.tutorial.data,
  }),
  data: () => ({
    eventCode: "",
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
  }),
  methods: {
    resetEnergy() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/energy/get";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = () => {
        this.api.isLoading = false;
        this.$store.commit("updateEnergy", {
          Energy: {
            quantity: 100,
          },
        });
      };
      this.$api.fetch(this.api);
    },
    resetFootprint() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/footprint/get";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = () => {
        this.api.isLoading = false;
      };
      this.$api.fetch(this.api);
    },
    addCoin(q, key) {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/item/updateMany";
      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };

      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        if (key == "goldcoin") {
          this.$store.commit("updateCoin", {
            Currency: {
              quantity: resp[0].quantity,
            },
          });
        }
      };
      this.api.params = {
        data: '[{"action":"add","itemKey":"' + key + '","quantity":' + q + "}]",
      };
      this.$api.fetch(this.api);
    },
    resetCoin() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/item/updateMany";
      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };

      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        this.$store.commit("updateCoin", {
          Currency: {
            quantity: resp[0].quantity,
          },
        });
      };
      this.api.params = {
        data: '[{"action":"set","itemKey":"goldcoin","quantity": 0 }]',
      };
      this.$api.fetch(this.api);
    },
    resetGoldenBrush() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/item/updateMany";
      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };

      this.api.callbackSuccess = () => {
        this.api.isLoading = false;
      };
      this.api.params = {
        data: '[{"action":"set","itemKey":"goldenbrush","quantity": 0 }]',
      };
      this.$api.fetch(this.api);
    },
    addTreasure(q, key) {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/item/updateMany";
      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };

      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        console.log(resp);
      };
      this.api.params = {
        data: '[{"action":"add","itemKey":"' + key + '","quantity":' + q + "}]",
      };
      this.$api.fetch(this.api);
    },
    resetTut(key) {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/tutorial/reset";
      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        console.log(resp);
        this.$store.commit("updateTutorial", {
          Tutorial: resp.Tutorial,
        });
        console.log(this.tutorial);
      };
      this.api.params = {
        key: key,
      };
      this.$api.fetch(this.api);
    },
    resetNews() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/news/reset";
      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = () => {
        this.api.isLoading = false;
      };
      this.$api.fetch(this.api);
    },
    async resetTicket() {
      let playerData;
      this.api.url =
        this.$api.servers.event +
        "/api/v2/" +
        this.$_getLocale() +
        "/game/event/player/load";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        playerData = resp;
      };
      this.api.params = {
        eventCode: this.eventCode,
        institutionId: this.auth.Institution ? this.auth.Institution.id : null
      };
      await this.$api.fetch(this.api);

      this.api.url =
        this.$api.servers.event +
        "/api/v2/" +
        this.$_getLocale() +
        "/game/event/player/save";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = () => {
        this.api.isLoading = false;
      };

      let data = JSON.parse(playerData.Save.data);
      data.isInitToken = this.$moment();
      data.token = 20;

      this.api.params = {
        eventCode: this.eventCode,
        version: playerData.Save.version ? playerData.Save.version : "1.0",
        build: playerData.Save.build + 1,
        data: JSON.stringify(data),
        totalStar: playerData.Player.totalStar,
        totalScore: playerData.Player.totalScore,
        totalAttempt: playerData.Player.totalAttempt,
        totalSubmission: playerData.Player.totalSubmission,
        progress: playerData.Player.totalStar / 45,
        schoolYear: this.auth.Classroom
          ? this.auth.Classroom.schoolYear
          : this.auth.User.schoolYear,
      };
      await this.$api.fetch(this.api);
    },
  },
};
</script>

<style>
.debug {
  position: fixed;
  top: 3%;
  left: 3%;
  z-index: 999999999999999;
}
</style>